
import { Getter, Action } from "vuex-class";
import { Component, Mixins, Vue } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import { HasFormErrors } from "@/mixins/has-form-errors";
import type { IndexPublicationPayload, Publication, PatchPublicationPayload, CreatePublicationPayload } from "@/store/modules/publications.store";
import { PublicationType } from "@/store/modules/publications.store";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormInput,
        FormError,
        FormSubmit,
    },
})
export default class DossierTabPublicationSettings extends Mixins(HasFormErrors) {
    @Getter("publications/all") publications!: Publication[];

    @Action("publications/index") indexPublications!: (payload: IndexPublicationPayload) => Promise<Publication[]>;
    @Action("publications/create") createPublication!: (payload: CreatePublicationPayload) => Promise<Publication>;
    @Action("publications/patch") patchPublication!: (payload: PatchPublicationPayload) => Promise<Publication>;

    PublicationType = PublicationType;

    payload: { [key in PublicationType]: Publication | CreatePublicationPayload | PatchPublicationPayload } = {
        WEBSITE: {
            type: PublicationType.website,
            url: "",
            activity_id: Number(this.$route.params.id),
        },
        IMMOVLAN: {
            type: PublicationType.immovlan,
            url: "",
            activity_id: Number(this.$route.params.id),
        },
        ZIMMO: {
            type: PublicationType.zimmo,
            url: "",
            activity_id: Number(this.$route.params.id),
        },
        IMMOSCOOP: {
            type: PublicationType.immoscoop,
            url: "",
            activity_id: Number(this.$route.params.id),
        },
        IMMOWEB: {
            type: PublicationType.immoweb,
            url: "",
            activity_id: Number(this.$route.params.id),
        },
        REALO: {
            type: PublicationType.realo,
            url: "",
            activity_id: Number(this.$route.params.id),
        },
        LOGIC_IMMO: {
            type: PublicationType.logicImmo,
            url: "",
            activity_id: Number(this.$route.params.id),
        },
    };

    async mounted() {
        const publications = await this.indexPublications({ q: { and: { activity_id: Number(this.$route.params.id) } } });

        publications.forEach((p) => {
            this.payload[p.type] = JSON.parse(JSON.stringify(p));
        });
    }

    async save(type: PublicationType) {
        try {
            const p = this.payload[type];

            if (p.url !== "") {
                if (p.hasOwnProperty("id")) {                   
                    // @ts-ignore
                    if (p.hasOwnProperty("dirty") && p.dirty) {
                        await this.update(type, { id: (p as Publication).id, url: p.url } as PatchPublicationPayload);
                    }
                } else {
                    await this.create(p as CreatePublicationPayload);
                }
            }
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        }
    }

    async create(p: CreatePublicationPayload) {
        this.payload[p.type] = await this.createPublication(p);

        this.$toast.open({ message: "Publicaties aangepast!" as string, type: "success", position: 'bottom-left' });
    }

    async update(type: PublicationType, p: PatchPublicationPayload) {
        this.payload[type] = await this.patchPublication(p);

        this.$toast.open({ message: "Publicaties aangepast!" as string, type: "success", position: 'bottom-left' });
    }
}
